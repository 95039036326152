import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Button from "../../elements/Button";
import { device } from "../../helpers/mediaQueries";

const CardLayout = styled.div`
	position: relative;
	justify-self: center;
	display: grid;
	grid-template: min-content 1fr / 1fr;

	width: 100%;
	min-height: 1px;
	height: 100%;
	border-radius: 5px;
	overflow: hidden;

	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

	img {
		width: 100%;
		height: 250px;
		object-fit: cover;
	}
`;

const CardDetail = styled.div`
	display: grid;
	grid-template: auto auto 1fr / 1fr;
	justify-items: center;

	text-align: center;
	width: 100%;
	height: 100%;
	padding-bottom: 2em;

	background: white;

	h6 {
		margin: 1em 1em 0;
		font-weight: 600;
		font-size: 24px;
		font-family: "Arial", sans-serif;
		color: #85496c;
		text-transform: uppercase;
	}

	p {
		margin: 2em 0 2.5em;
		font-size: 16px;
		font-family: "Cormorant Garamond", serif;
		font-weight: 400;
		line-height: 27px;
	}

	button {
		align-self: end;
		height: auto;
	}

	@media ${device.mobileL} {
		&& p {
			margin: 2em 0;
		}
	}
`;

export default function Card(props) {
	let history = useHistory();

	function handleClick() {
		history.push(`/${props.type || "events"}/${props.id}`);
	}

	return (
		<CardLayout>
			<img src={props.imgUrl} alt="" />
			<CardDetail>
				<h6>{props.name}</h6>
				<p>{props.description}</p>
				<Button onClick={handleClick}>Ver Más</Button>
			</CardDetail>
		</CardLayout>
	);
}

// export default function CardDetalles(props) {
// 	let history = useHistory();

// 	function handleClick() {
// 		history.push(`/${props.type}/${props.id}`);
// 	}

// 	return (
// 		<CardLayout>
// 			<img src={props.imgUrl} alt="" />
// 			<CardDetail>
// 				<h6>{props.name}</h6>
// 				<p>{props.description}</p>
// 				<Button onClick={handleClick}>Ver Más</Button>
// 			</CardDetail>
// 		</CardLayout>
// 	);
// }

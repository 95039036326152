import styled from "styled-components";
import { device } from "../../helpers/mediaQueries";

export const FooterLayout = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	margin: 0;

	color: #fff;

	h1 {
		text-transform: uppercase;
		font-weight: bold;
		margin: 0px 0 20px 0;
		font-size: 0.8rem;
	}

	h2 {
		margin: 0;
		margin-bottom: 2em;

		text-align: center;
		font-size: 0.8rem;
		font-weight: bold;
		color: #fff;
	}

	p {
		font-family: "Cormorant Garamond", serif;
		font-weight: 400;
		font-size: 20px;
		line-height: 27px;
		margin: 1em 0;
	}

	.Footer_content {
		display: grid;
		grid-template-columns: 3fr 2fr 2fr;
		gap: 10px;

		padding: 85px 1em;
		background: var(--color-secondary);
		color: #fff;

		&-about {
			justify-self: center;
			img {
				margin: 48px 0;
				height: 15vh;
				width: auto;
			}
		}

		&-links {
			a {
				text-decoration: none;
				font-size: 20px;
				color: #fff;
				display: block;
				margin: 0.5em;
			}
		}

		&-redes {
			display: flex;
			justify-content: center;
			i {
				font-size: 3rem;
				color: white;
				margin: 0.5em 0.2em 0;
			}
		}
	}

	.Footer_copyright {
		padding: 14px 0;

		color: #fff;
		font-size: 1.1rem;
		text-align: center;

		background: var(--color-primary);
		border-top: solid 1px var(--color-primary);
	}

	@media ${device.tablet} {
		text-align: center;

		h1 {
			font-size: 1rem;
			margin: 1rem;
		}

		.Footer_content {
			display: grid;
			grid-template-columns: 1fr;
			gap: 10px;

			img {
				height: 10vh;
			}

			&-links {
				margin: 0;
				padding: 0;
				li {
					width: 70%;
					margin: 0 auto;
				}
				a {
					padding: 1em 2em;
					margin-bottom: 0.5rem;
					border-bottom: 1px solid gray;
				}
			}
		}
		.Footer_copyright {
			span {
				display: block;
			}
		}
	}
`;

import React, { useEffect, useContext } from "react";
import styled from "styled-components";

import LoadingOverlay from "../components/LoadingOverlay";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Eventos from "../components/Eventos";
import Amenidades from "../components/Amenidades";
import Promociones from "../components/Promociones";
import Opiniones from "../components/Opiniones";
import Noticias from "../components/Noticias";
import Footer from "../components/Footer";

import { staticContext } from "../contexts/Static.context";

const LandingPage = styled.div`
	height: 100%;
	min-height: 100vh;
	background-image: url("assets/static/fondo.png");
	background-color: #f9f8f7;
	background-repeat: repeat;
	background-size: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
	position: relative;
	font-family: "Cormorant Garamond", serif;
`;

export default function Landing() {
	const { state, getStaticData } = useContext(staticContext);

	useEffect(() => {
		if (state.offers.length <= 0) {
			getStaticData();
		}
	}, [state, getStaticData]);

	if (state.isLoading || !state.isBannerLoaded) {
		return <LoadingOverlay />;
	}

	return (
		<LandingPage>
			<Header />
			<Banner
				messages={state.bannerMsgs}
				images={state.bannerImgs}
				services={state.allServices}
			/>
			<Eventos items={state.events} />
			<Amenidades />
			<Promociones items={state.offers} />
			<Opiniones items={state.reviews} />
			<Noticias noticias={state.nextEvents} />
			<Footer />
		</LandingPage>
	);
}

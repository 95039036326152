import React, { useState, memo } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

import Modal from "react-bootstrap/Modal";
import Button from "../../elements/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import s from "./styles";

import api from "../../api";

function BookingEventModal(props) {
	const {
		title = "",
		date = new Date(),
		name = "",
		email = "",
		phone = "",
		type,
		max_assistenten,
		services,
		onHide,
	} = props;

	const { register, handleSubmit, errors } = useForm();
	const [startDate, setStartDate] = useState(new Date(date));
	const [confirmModal, setConfirmModal] = useState("");

	const onSubmit = async (event) => {
		const formData = new FormData();

		Object.keys(event).forEach((key) => {
			formData.append(key, event[key]);
		});

		formData.append("date", startDate.toISOString().slice(0, 10));

		await api.Bookings.create(formData);

		setConfirmModal("ok");
	};

	if (confirmModal) {
		return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{ opacity: "1" }}
			>
				<s.Modal_body>
					<s.Modal_icon>
						<i class="fas fa-check"></i>
					</s.Modal_icon>
					<s.Modal_tile>Solicitud enviada</s.Modal_tile>
					<s.Modal_text>
						Gracias por su preferencia. <br />
						En breve nos pondremos en contacto para continuar con el proceso.{" "}
						<br />
					</s.Modal_text>
					<s.Modal_text_footer>
						Contamos con terminal física en nuestra oficina y terraza para el
						pago con tarjetas.
					</s.Modal_text_footer>
					<s.Modal_button onClick={onHide}>Aceptar</s.Modal_button>
				</s.Modal_body>
			</Modal>
		);
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			style={{ opacity: "1" }}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Solicita tu reservación
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<s.StepContent>
						<Form.Group>
							<s.H1>Datos del Evento</s.H1>
							<Row>
								<Col className="text-left">
									<Form.Label>Seleccione una fecha:</Form.Label> <br />
									<DatePicker
										dateFormat="yyyy-MM-dd"
										selected={startDate}
										onChange={(date) => setStartDate(date)}
									/>
								</Col>
							</Row>

							<Form.Label>Agregue un nombre a su evento *</Form.Label>
							<Form.Control
								name="title"
								defaultValue={title}
								ref={register({ required: true, maxLength: 64 })}
							/>
							{errors.title?.type === "required" && (
								<s.Error type="invalid">
									El título del evento es obligatorio.
								</s.Error>
							)}
							{errors.title?.type === "maxLength" && (
								<s.Error type="invalid">
									La longitud máxima es de 64 caracteres.
								</s.Error>
							)}

							<Form.Label>Seleccione el tipo de evento</Form.Label>
							<Form.Control
								as="select"
								defaultValue={type}
								name="type"
								ref={register}
							>
								{services.map((service) => (
									<option key={service.id} value={service.id}>
										{service.name}
									</option>
								))}
							</Form.Control>

							<Form.Label>Ingrese el número de asistentes *</Form.Label>
							<Form.Control
								name="max_assistenten"
								defaultValue={max_assistenten}
								ref={register({ required: true, pattern: /^[0-9]*$/i })}
							/>
							{errors.max_assistenten?.type === "pattern" && (
								<s.Error type="invalid">Ingrese un número válido.</s.Error>
							)}
						</Form.Group>

						<Form.Group>
							<s.H1>Cliente</s.H1>
							<Form.Label>Nombre *</Form.Label>
							<Form.Control
								name="name"
								defaultValue={name}
								ref={register({ required: true })}
							/>
							{errors.name?.type === "required" && (
								<s.Error type="invalid">El nombre es obligatorio.</s.Error>
							)}

							<Form.Label>Correo *</Form.Label>
							<Form.Control
								name="email"
								defaultValue={email}
								ref={register({ required: true, pattern: /^\S+@\S+$/i })}
							/>
							{errors.email?.type === "required" && (
								<s.Error type="invalid">
									La cuenta de correo es obligatoria.
								</s.Error>
							)}
							{errors.email?.type === "pattern" && (
								<s.Error type="invalid">
									Ingrese una cuenta de correo válida.
								</s.Error>
							)}

							<Form.Label>Teléfono *</Form.Label>
							<Form.Control
								name="phone"
								defaultValue={phone}
								ref={register({
									required: true,
									minLength: 6,
									pattern: /^[0-9]*$/i,
								})}
							/>
							{errors.phone?.type === "required" && (
								<s.Error type="invalid">El telefono es obligatorio.</s.Error>
							)}
							{errors.phone?.type === "minLength" ||
								(errors.phone?.type === "pattern" && (
									<s.Error type="invalid">
										Ingrese un número de teléfono válido.
									</s.Error>
								))}
						</Form.Group>
					</s.StepContent>

					<Modal.Footer className="justify-content-space-between">
						<Button onClick={onHide} type="button" outline>
							Cancelar
						</Button>
						<Button type="submit">Enviar</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default memo(BookingEventModal);

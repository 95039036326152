import React, { useContext } from "react";

import Menu from "./Menu";
import Submenu from "./Submenu";
import { HeaderBar, HeaderLogo, Nav, HeaderReservation } from "./styles";
import { staticContext } from "../../contexts/Static.context";
import BookingEventModal from "../BookingEventModal";
import useToggle from "../../hooks/useToggle";

function Header() {
	const { state } = useContext(staticContext);
	const [isModalActive, toggleIsModalActive] = useToggle(false);

	const { events: eventsList = [] } = state;
	const events = eventsList.map((event) => ({
		text: event.name,
		href: `/services/${event.id}`,
	}));

	return (
		<React.Fragment>
			<HeaderBar className="Header">
				<HeaderLogo src="/assets/img/logo_quinta_fabiana_nuevo_color.svg" alt="" />
				<Nav className="Header_links">
					<Menu href="/" text="Home" exact />
					<Menu href="/services" text="Servicios">
						<Submenu items={events} />
					</Menu>
					<Menu href="/events" text="Eventos" exact />
					<Menu href="/offers" text="Paquetes" exact />
					<Menu href="/gallery" text="Galería" exact />
				</Nav>
				<div className="Header_phone" />
				<HeaderReservation>
					<a onClick={toggleIsModalActive}>
						Haz tu reservación
						<i className="fas fa-angle-right" />
					</a>
				</HeaderReservation>
			</HeaderBar>
			{isModalActive && (
				<BookingEventModal
					addType
					services={state.allServices}
					show={isModalActive}
					onHide={toggleIsModalActive}
					onSave={null}
				></BookingEventModal>
			)}
		</React.Fragment>
	);
}

export default Header;

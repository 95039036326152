import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import LoadingOverlay from "../components/LoadingOverlay";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Servicios from "../components/Servicios";
import Footer from "../components/Footer";

import { staticContext } from "../contexts/Static.context";

const LandingPage = styled.div`
	height: 100%;
	min-height: 100vh;
	background-image: url("assets/static/fondo.png");
	background-color: #f9f8f7;
	background-repeat: repeat;
	background-size: 100%;
	display: grid;
	grid-template-rows: auto minmax(130vh, 1fr) auto;
	position: relative;
`;

export default function SingleService() {
	const { state, getStaticData } = useContext(staticContext);
	let params = useParams();

	useEffect(() => {
		if (!state.services[params.id]) {
			getStaticData({ serviceType: params.id, offers: false, reviews: false });
		}
	}, [getStaticData, params]);

	if (state.isLoading) {
		return <LoadingOverlay />;
	}

	const event = state.events.find((e) => e.id === params.id) || {};

	return (
		<LandingPage>
			<Header />
			<Banner messages={state.bannerMsgs} images={state.bannerImgs} />
			<Servicios
				items={state.services[params.id]}
				title={event && event.name}
				description={event && event.description}
			/>
			<Footer />
		</LandingPage>
	);
}

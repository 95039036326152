import React from 'react';
import styled from 'styled-components';
import { device } from '../../helpers/mediaQueries';

const AmenidaesLayout = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 800px;
	overflow: hidden;
	text-align: center;
	margin: 0 auto;
	box-sizing: border-box;
	gap: 1em;

	padding: 3em;

	background: rgba(0, 0, 0, .2) fixed no-repeat 35% 50%;
	background-size: cover;
	background-image: url('./assets/static/Img_amenidades_difuminado.png');
	background-blend-mode: overlay;

	img {
		justify-self: center;
		width: 90%;
	}

	@media ${device.mobileL} {
		height: max-content;
		img {
			margin-top: 1rem;
			width: 35%;
		}
	}
`;

const AmenidadesWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	max-width: 1300px;

	@media ${device.mobileL} {
		grid-template-columns: 1fr;
	}
`;

const AmenidadesText = styled.div`
	justify-self: center;

	color: #fff;
	h1 {
		font-family: 'Exo', sans-serif;
		text-transform: inherit;
		font-weight: 800;
		text-transform: capitalize;
		padding: 0 0 90px 0;
		margin: -6px 0 0 0;
		background: url('/assets/static/Icn_HojasViña_blanca.png') no-repeat center 70px;
	}
	h4 {
		text-align: center;
		color: #f3bb32;
		margin: 1rem 0;
		font-weight: 600;
		font-size: 32px;
		font-family: 'Arial', sans-serif;
	}
	ul {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		text-align: left;
		margin: .5rem 0;
	}
	li {
		justify-self: center;
		text-align: center;
		list-style-type: none;
		margin: 0.25rem 0;
		padding: 0;
		font-size: 1.5rem;
	}

	.double {
		grid-column: 1 / span 2;
	}

	@media ${device.mobileL} {
		ul {
			padding: 0;
			grid-template-columns: 1fr;
		}
	}
`;

export default function Amenidades() {
	return (
		<AmenidaesLayout>
			<AmenidadesWrapper>
				<AmenidadesText>
					<h1>Amenidades</h1>
					<h4>Terraza</h4>
					<ul>
						<li>Motor Lobby</li>
						<li>Área de viñedo</li>
						<li>Área de recepción</li>
						<li>Sanitarios</li>
						<li>Habitación</li>
						<li>Valet parking</li>
						<li>Alberca infinita</li>
						<li>Estacionamiento</li>
					</ul>
					<h4>Servicios fuera de la terraza</h4>
					<ul>
						<li className="double">
							Oficina dentro de la ZMG(Plaza Tepeyac, local E9)
						</li>
					</ul>
				</AmenidadesText>
				<img src="/assets/static/Img_amenidades_circulo.png" alt="" />
			</AmenidadesWrapper>
		</AmenidaesLayout>
	);
}

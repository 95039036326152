import React, { useState } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { device } from "../../helpers/mediaQueries";

import useToggle from "../../hooks/useToggle";
import Button from "../../elements/Button";
import BookingEventModal from "../BookingEventModal";

const FormWrapper = styled.div`
	position: absolute;
	bottom: 0;
	z-index: 50;
	height: max-content;
	width: 100%;

	&.hideDown {
		overflow: hidden;
	}
`;

const FormLayout = styled.form`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
	bottom: 0;
	z-index: 50;
	font-size: 14px;
	padding: 20px 0;
	width: 100%;
	max-width: 950px;
	min-height: 50px;
	margin: 4em auto 0;
	border-radius: 5px;

	background: #303944;

	transition: all 1s ease;

	select {
		height: 100%;
		padding: 10px;
	}

	.react-datepicker__input-container input {
		padding: 1em;
	}

	&.hideDown {
		background: #85496c;
		position: relative;
		bottom: -90px;
	}

	@media ${device.tablet} {
		flex-direction: column;

		width: max-content;
		margin: 0 auto;
		padding: 2rem 2rem 1rem;

		.react-datepicker__input-container input {
			margin: 1em;
			width: 320px;
		}

		&.hideDown {
			background: #85496c;
			position: relative;
			bottom: -220px;
		}
	}

	@media ${device.tablet} {
		bottom: -200px;
		padding: 2em 5em;

		select,
		.react-datepicker__input-container input {
			width: 320px;
		}

		&.hideDown {
			background: #85496c;
			position: relative;
			width: 0px;
			bottom: -210px;
		}
	}

	@media ${device.mobileL} {
		padding: 2em 2.5em;
		select,
		.react-datepicker__input-container input {
			width: 280px;
		}
	}
`;

const Hide = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2em;
	top: -1.5em;
	height: 1.5em;
	width: 2em;
	padding: 5px 0.5em;
	background: inherit;
	color: white;
	border-top-left-radius: 100px;
	border-top-right-radius: 100px;
	cursor: pointer;

	i {
		transition: transform 0.7s ease;
	}

	&.hidden i {
		transform: rotateZ(180deg);
	}
`;

export default function Form({ services = [] }) {
	const [startDate, setStartDate] = useState(new Date());
	const [activeService, setActiveService] = useState("");
	const [isToggled, toggleInput] = useToggle(false);
	const [isModalActive, toggleIsModalActive] = useToggle(false);

	return (
		<React.Fragment>
			<FormWrapper className={`${isToggled ? "hideDown" : ""}`}>
				<FormLayout className={`${isToggled ? "hideDown" : ""}`}>
					<Hide
						onClick={toggleInput}
						className={`${isToggled ? "hidden" : ""}`}
					>
						<i className="fas fa-chevron-up" />
					</Hide>
					<select onChange={(e) => setActiveService(e.target.value)}>
						<option value="">Selecciona el tipo de evento que deseas</option>
						{services.map(
							(service, i) =>
								service.enabled === "1" && (
									<option key={i} value={service.id}>
										{service.name}
									</option>
								)
						)}
					</select>
					<DatePicker
						dateFormat="yyyy-MM-dd"
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						placeholderText="Elige una fecha"
					/>
					<Button type="button" onClick={toggleIsModalActive}>
						REVISAR DISPONIBILIDAD
					</Button>
				</FormLayout>
			</FormWrapper>
			{isModalActive && (
				<BookingEventModal
					addType
					date={startDate.toISOString().slice(0, 19)}
					type={activeService}
					services={services}
					show={isModalActive}
					onHide={toggleIsModalActive}
					onSave={null}
				></BookingEventModal>
			)}
		</React.Fragment>
	);
}

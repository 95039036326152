import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import LoadingOverlay from "../components/LoadingOverlay";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Eventos from "../components/Eventos";
import Footer from "../components/Footer";

import { staticContext } from "../contexts/Static.context";

// background-image: url("assets/static/fondo.png"); esta linea va en la constante LandingPage
const LandingPage = styled.div`
	height: 100%;
	min-height: 100vh;
	background-image: url("assets/static/fondo.png");
	background-color: #f9f8f7;
	background-repeat: repeat;
	background-size: 100%;
	display: grid;
	grid-template-rows: auto minmax(130vh, 1fr) auto;
	position: relative;
`;

export default function Landing() {
	const { state, getStaticData } = useContext(staticContext);

	useEffect(() => {
		if (state.events.length <= 0) {
			getStaticData();
		}
	}, [state, getStaticData]);

	if (state.isLoading) {
		return <LoadingOverlay />;
	}

	return (
		<LandingPage>
			<Header />
			<Banner messages={state.bannerMsgs} images={state.bannerImgs} />
			<Eventos items={state.events} />
			<Footer />
		</LandingPage>
	);
}
